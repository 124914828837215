import React, { useState } from "react";
import { Link } from "react-router-dom";
import ShareLinks from "./share-links";

export default function SessionStarter(props) {
  const [sessionId, setSessionId] = useState(null);
  const [user, setUser] = useState(null);
  const [otherUser, setOtherUser] = useState(null);
  const [namesReady, setNamesReady] = useState(false);

  let msg;

  if (!user || !otherUser || !namesReady) {
    const handleUserChange = (e) => setUser(e.target.value);
    const handleOtherUserChange = (e) => setOtherUser(e.target.value);
    return (
      <div className="container">
        <h1 className="display-4 text-center">Welcome to GrubGrindr!</h1>
        <h4 className="text-center">
          We'll help you and your friend pick out some great delivery
        </h4>
        <hr />
        <p>First, put in your name and their name.</p>
        <form noValidate autoComplete="off">
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              value={user}
              onChange={handleUserChange}
              placeholder="Your Name"
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              value={otherUser}
              onChange={handleOtherUserChange}
              placeholder="Other Persons Name"
            />
          </div>
          <button
            className="btn btn-primary"
            onClick={() => setNamesReady(true)}
          >
            {" "}
            Next{" "}
          </button>
        </form>
        <br />
        <div className="alert alert-warning">
          When prompted for location access, make sure you hit "allow" so we can
          find a good spot near you
        </div>
      </div>
    );
  }

  if (!sessionId) {
    msg = (
      <div className="container h-100">
        <div className="row justify-content-center mt-2">
          <strong>Finding places nearby...</strong>
          <div
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></div>
        </div>
      </div>
    );

    console.log("attempting to create new session");
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("got coordinates");
        const lat = position.coords.latitude;
        const lon = position.coords.longitude;
        console.log("making api call to create session");
        fetch(
          "https://3ettm39saf.execute-API.us-east-1.amazonaws.com/prod/session",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              lat: lat,
              lon: lon,
            }),
          }
        ).then((response) => {
          response.text().then((data) => setSessionId(data));
        });
      },
      (error) => {
        if (error.code === 1) {
          alert("You need to hit allow");
          // TODO: reprompt?
        }
      }
    );
  } else {
    msg = (
      <ShareLinks sessionId={sessionId} otherUser={otherUser} user={user} />
    );
  }
  return msg;
}
