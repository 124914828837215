import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Swipeable, direction } from "react-deck-swiper";
import { Popover, OverlayTrigger, Button } from "react-bootstrap";

const helpPopover = (
  <Popover id="swipe-help-popover">
    <Popover.Title as="h3">What's this?</Popover.Title>
    <Popover.Content>
      See something good? Swipe right or hit the Yep! button.
      <br />
      Rather skip it? Swipe left or hit the Nope! button.
    </Popover.Content>
  </Popover>
);

const questionIcon = (
  <svg
    class="bi bi-question-circle"
    width="3em"
    height="3em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z"
      clip-rule="evenodd"
    />
    <path d="M5.25 6.033h1.32c0-.781.458-1.384 1.36-1.384.685 0 1.313.343 1.313 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.007.463h1.307v-.355c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.326 0-2.786.647-2.754 2.533zm1.562 5.516c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
  </svg>
);

const HelpButton = () => (
  <OverlayTrigger trigger="click" placement="top" overlay={helpPopover}>
    {questionIcon}
  </OverlayTrigger>
);

const yelp_logo = (
  <img
    src="/yelp_logo.png"
    height="35px"
    style={{ marginTop: "-10px", marginLeft: "-3px" }}
  />
);

export default class SessionView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voter_name: props.match.params.voter_id,
      session_id: props.match.params.session_id,
      choices: null,
    };
    let url =
      "https://3ettm39saf.execute-api.us-east-1.amazonaws.com/prod/session?id=" +
      this.state.session_id +
      "&voter_id=" +
      this.state.voter_name;
    fetch(url).then((response) => {
      response.json().then((data) => {
        this.setState({
          choices: data.Items.map((i) => ({
            name: i.name.S,
            img_url: i.img_url.S,
            rating: i.rating.N,
            categories: i.categories.SS,
            yelp_id: i.yelp_id.S,
            review_count: i.review_count.N,
            url: i.url.S,
          })),
        });
        console.log(data.Items);
      });
    });
  }

  render() {
    if (!this.state.session_id) {
      return <Redirect to="/" />;
    }
    if (this.state.choices) {
      if (this.state.choices.length === 0) {
        return <Redirect to={"/results/" + this.state.session_id} />;
      }
      const handleOnSwipe = (swipeDirection) => {
        if (swipeDirection === direction.RIGHT) {
          console.log("swipe right");
          sendVote(
            this.state.voter_name,
            this.state.choices[0].yelp_id,
            this.state.session_id,
            "yes"
          );
        }

        if (swipeDirection === direction.LEFT) {
          console.log("swipe left");
          sendVote(
            this.state.voter_name,
            this.state.choices[0].yelp_id,
            this.state.session_id,
            "no"
          );
        }
        const newChoices = this.state.choices.slice(1);

        this.setState({ choices: newChoices });
        console.log(this.state.choices.length);
      };
      const renderButtons = ({ right, left }) => (
        <div className="container">
          <div className="row">
            <div className="col">
              <button type="button" className="btn btn-primary" onClick={left}>
                Nope!
              </button>
            </div>
            <div className="col">
              <HelpButton />
            </div>
            <div className="col">
              <button type="button" className="btn btn-primary" onClick={right}>
                Yes!
              </button>
            </div>
          </div>
        </div>
      );
      console.log(this.state.choices[0].url);
      const category_list = this.state.choices[0].categories.map((c) => (
        <li>{c}</li>
      ));
      const format_rating = (rating) => {
        const rating_strings = {
          "1": "1_",
          "1.5": "1_half",
          "2": "2_",
          "2.5": "2_half",
          "3": "3_",
          "3.5": "3_half",
          "4": "4_",
          "4.5": "4_half",
          "5": "5",
        };
        return rating_strings[String(rating)];
      };
      const star_rating =
        "/yelp_stars/regular_" +
        format_rating(this.state.choices[0].rating) +
        ".png";
      return (
        <div className="container mt-3">
          <Swipeable onSwipe={handleOnSwipe} renderButtons={renderButtons}>
            <div className="card">
              <img
                src={this.state.choices[0].img_url}
                className="card-img-top"
              />
              <h5 className="card-title">{this.state.choices[0].name}</h5>
              <div className="card-text">
                Categories:
                <ul>{category_list}</ul>
                Rating on{" "}
                <a href={this.state.choices[0].url} target="_blank">
                  {yelp_logo}
                </a>
                : <img src={star_rating} />
                <h6>based on {this.state.choices[0].review_count} reviews</h6>
              </div>
            </div>
          </Swipeable>
        </div>
      );
    } else {
      return (
        <div className="container h-100">
          <div className="row justify-content-center mt-2">
            <strong>Loading...</strong>
            <div
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></div>
          </div>
        </div>
      );
    }
  }
}

const sendVote = (voterName, yelpId, sessionId, vote) => {
  const url =
    "https://3ettm39saf.execute-api.us-east-1.amazonaws.com/prod/vote";
  fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      voter: voterName,
      yelp_id: yelpId,
      session_id: sessionId,
      vote: vote,
    }),
  });
};
