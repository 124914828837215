import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";

export default function ShareLinks(props) {
  const arrow_icon = (
    <svg
      className="bi bi-arrow-return-right"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M10.146 5.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 9l-2.647-2.646a.5.5 0 010-.708z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M3 2.5a.5.5 0 00-.5.5v4A2.5 2.5 0 005 9.5h8.5a.5.5 0 000-1H5A1.5 1.5 0 013.5 7V3a.5.5 0 00-.5-.5z"
        clip-rule="evenodd"
      />
    </svg>
  );

  const shareIcon = (
    <svg
      class="bi bi-box-arrow-up"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M4.646 4.354a.5.5 0 00.708 0L8 1.707l2.646 2.647a.5.5 0 00.708-.708l-3-3a.5.5 0 00-.708 0l-3 3a.5.5 0 000 .708z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M8 11.5a.5.5 0 00.5-.5V2a.5.5 0 00-1 0v9a.5.5 0 00.5.5z"
        clip-rule="evenodd"
      />
      <path
        fill-rule="evenodd"
        d="M2.5 14A1.5 1.5 0 004 15.5h8a1.5 1.5 0 001.5-1.5V7A1.5 1.5 0 0012 5.5h-1.5a.5.5 0 000 1H12a.5.5 0 01.5.5v7a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h1.5a.5.5 0 000-1H4A1.5 1.5 0 002.5 7v7z"
        clip-rule="evenodd"
      />
    </svg>
  );
  const [hasShared, setHasShared] = useState(false);
  const sessionId = props.sessionId;
  const otherUser = props.otherUser;
  const user = props.user;
  const shareData = {
    title: "GrugGrindr Invite",
    url: "https://grubgrindr.com/session/" + sessionId + "/" + otherUser,
    text: user + " wants your help picking out where to order from",
  };
  var msg;

  if (hasShared === false) {
    if ("share" in navigator) {
      // show share button
      msg = (
        <button
          className="btn btn-primary"
          onClick={() =>
            navigator.share(shareData).then(() => setHasShared(true))
          }
        >
          {shareIcon} Invite {otherUser} to vote on places
        </button>
      );
    } else {
      msg = (
        <div>
          <p className="mt-2">
            Send this first link to your friend. You can do that by
            long-pressing the link, and selecting "Share".
          </p>
          {arrow_icon}
          <Link to={"/session/" + sessionId + "/" + otherUser}>
            Link for your friend to vote
          </Link>
          <hr />
          And then, click this button:
          <br />
          <Link
            to={"/session/" + sessionId + "/" + user}
            className="btn btn-primary"
          >
            Make your picks
          </Link>
        </div>
      );
    }
  } else {
    msg = <Redirect to={"/session/" + sessionId + "/" + user} />;
  }

  return <div className="container text-center">{msg}</div>;
}
