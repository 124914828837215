import React, { useState } from "react";

export default function ResultView(props) {
  const session_id = props.match.params.session_id;
  const [result, setResult] = useState(null);
  if (!result) {
    const url =
      "https://3ettm39saf.execute-api.us-east-1.amazonaws.com/prod/result?id=" +
      session_id;
    fetch(url).then((result) => {
      result.json().then((data) => {
        if (data) {
          setResult(data);
        } else {
          setResult([]);
        }
      });
    });
    return (
      <div className="container h-100">
        <div className="row justify-content-center mt-2">
          <strong>Loading...</strong>
          <div
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></div>
        </div>
      </div>
    );
  }
  if (result.length === 0) {
    return (
      <div className="container mt-3">
        <div class="alert alert-info">
          Waiting for the other person to vote... <br />
          Try back later
        </div>
      </div>
    );
  }
  const category_list = result.categories.SS.map((c) => <li>{c}</li>);
  const format_rating = (rating) => {
    const rating_strings = {
      "1": "1_",
      "1.5": "1_half",
      "2": "2_",
      "2.5": "2_half",
      "3": "3_",
      "3.5": "3_half",
      "4": "4_",
      "4.5": "4_half",
      "5": "5",
    };
    return rating_strings[String(rating)];
  };
  const star_rating =
    "/yelp_stars/regular_" + format_rating(result.rating.N) + ".png";

    const yelp_logo = (<img src="/yelp_logo.png" height="35px" style={{marginTop: "-10px", marginLeft: "-3px"}} />);

  return (
    <div className="container mt-3">
      <h1 className="display-4 text-center">Your should order from</h1>
      <div className="card">
        <img src={result.img_url.S} className="card-img-top" />
        <h5 className="card-title">{result.name.S}</h5>
        <div className="card-text">
          <div className="row">
            <div class="col">
              {" "}
              Categories:
          <ul>{category_list}</ul>
          Rating on <a href={result.url.S} target="_blank">{yelp_logo}</a>: <img src={star_rating} />
              <h6>based on {result.review_count.N} reviews</h6>
            </div>
            <div class="col">
              Give them a call at {result.phone.S}
              <br />
              Or read more on{" "}
              <a href={result.url.S} target="_blank">
          {yelp_logo}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
