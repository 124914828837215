import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ResultView from "./components/result-view";
import SessionStarter from "./components/session-starter";
import SessionView from "./components/session-view";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <div>
            <Switch>
              <Route
                path="/session/:session_id/:voter_id"
                component={SessionView}
              />
              <Route path="/results/:session_id" component={ResultView} />
              <Route path="/">
                <SessionStarter />
              </Route>
            </Switch>
          </div>
        </Router>
      </header>
    </div>
  );
}

export default App;
